<template>
  <div class="about">
    <!-- header-fixed为页面下滑后导航样式 -->
    <!-- 网站顶部 -->
    <OfficialOtherHead :homePageSetting="homePageSetting" />
    <!-- contactus -->
    <div class="main">
      <!-- 标题 -->
      <div class="content-title">
        <img class="hidden-xs" :src="homePageSetting.contactUs.bannerImgUrl" />
        <img class="hidden-mx"  :src="homePageSetting.contactUs.bannerImgUrl" />
        <div class="content-box">
          <h1>{{homePageSetting.contactUs.title}}</h1>
          <div v-for="(item, index) in homePageSetting.contactUs.briefList" :key="index">
              {{ item }}
            </div>
        </div>
      </div>
      <!-- 内容 -->
      <div class="panel">
        <div class="container">
          <div class="container-left">
            <div class="container-left-item">
              <h4>公司地址</h4>
              <p>{{homePageSetting.companyAddress}}</p>
            </div>
            <div class="container-left-item">
              <h4>邮编</h4>
              <p>{{homePageSetting.contactUs.postcode}}</p>
            </div>
            <div class="container-left-item">
              <h4>联系电话</h4>
              <p>{{homePageSetting.telephone}}</p>
            </div>
            <div class="container-left-item">
              <h4>传真</h4>
              <p>{{homePageSetting.faxNumber}}</p>
            </div>
          </div>
          <div class="container-right">
            <div class="cr-title">给我们留言：</div>
            <el-form
              :model="dataModel"
              :rules="rules"
              ref="ruleForm"
              label-width="0"
              class="demo-ruleForm cr-form"
            >
              <div class="cr-label">留言标题<span>（选填）</span></div>
              <el-form-item label="" prop="title">
                <el-input
                  v-model="dataModel.title"
                  placeholder="请输入标题"
                  maxlength="250"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <div class="cr-label">
                留言内容<span>（必填，500字符以内）</span>
              </div>
              <el-form-item label="" prop="message">
                <el-input
                  type="textarea"
                  v-model="dataModel.message"
                  placeholder="请输入留言内容"
                  maxlength="500"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <div class="cr-label">
                您的联系方式<span>（电话或邮箱，选填）</span>
              </div>
              <el-form-item label="" prop="contactInformation">
                <el-input
                  v-model="dataModel.contactInformation"
                  placeholder="请输入联系方式"
                  maxlength="250"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm()" :loading="btnLoading">
                  提交留言</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="map">
          <img :src="homePageSetting.contactUs.mapImgUrl" />
        </div>
      </div>
    </div>
    <!-- end contactus -->
    <!-- 网站底部 -->
    <OfficialFoot :homePageSetting="homePageSetting" />
  </div>
</template>

<script>
import OfficialOtherHead from "./OfficialOtherHead";
import OfficialFoot from "./OfficialFoot";
import { getHomePageSetting ,setSeo} from "../api/defaultDataModel";
export default {
  name: "ContactUs",
  components: {
    OfficialOtherHead,
    OfficialFoot,
  },
  data: function () {
    return {
      btnLoading:false,
      homePageSetting: getHomePageSetting(),
      dataModel: {
        title: undefined,
        message: undefined,
        contactInformation: undefined,
      },
      rules: {
        title: [
          { max: 250, message: "长度在 250 个字符以内", trigger: "blur" },
        ],
        message: [
          { required: true, message: "请输入留言内容", trigger: "blur" },
          {
            min: 1,
            max: 500,
            message: "长度在 500 个字符以内",
            trigger: "blur",
          },
        ],
        contactInformation: [
          { max: 250, message: "长度在 250 个字符以内", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    //当前关于数据

    //首页数据
    this.$http
      .get(this.TzConfig.BASE_URL + "/h5/homePageSetting/getSetting")
      .then((res) => {
        if (8000 === res.data.code) {
          this.homePageSetting = res.data.data;
          setSeo(this.homePageSetting.seoTitle,this.homePageSetting.seoDescription,this.homePageSetting.seoKeyword)
        }
      })
      .catch((err) => {
      });
  },
  methods: {
    submitForm() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          this.$http
            .post(this.TzConfig.BASE_URL + "/h5/messageBoard/addMessageBoard", {
              ...this.dataModel,
            })
            .then((res) => {
              this.btnLoading = false;
              if (8000 === res.data.code) {
                this.$alert(res.data.message,"提示", {
                  confirmButtonText: "确定"
                });
                this.dataModel = {
                  title: undefined,
                  message: undefined,
                  contactInformation: undefined,
                };
              } else {
                this.$alert(res.data.message,"提示", {
                  confirmButtonText: "确定"
                });
              }
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        } else {
          this.$message.error("请输入留言内容");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.main {
  margin: 88px auto 0;
  width: 100%;
}
.content-title {
  max-width: 1920px;
  position: relative;
  height: 360px;
  overflow: hidden;
}
.hidden-xs {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hidden-mx {
  display: none;
}
.content-box {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  display: -webkit-flex;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}
.content-box h1,
.content-box div {
  text-align: center;
  color: #fff;
}
.content-box h1 {
  font-size: 40px;
  line-height: 52px;
}
.content-box div {
  margin: 13px 0 0;
  font-size: 16px;
  line-height: 22px;
}
.panel {
  position: relative;
  z-index: 999;
  margin: 0 auto;
  width: 100%;
}
.container {
  display: -webkit-flex;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0 auto 114px;
  padding: 90px 100px 0;
  max-width: 1070px;
  background: #fff;
}
.container-left {
  display: -webkit-flex;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  width: calc(100% - 520px);
}
.container-left-item {
  margin: 0 0 40px;
}
.container-left-item h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}
.container-left-item p {
  margin: 10px 0 0;
  font-size: 16px;
  line-height: 22px;
}
.container-right {
  width: 480px;
}
.cr-title {
  margin: 0 0 8px;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}
.cr-label {
  font-size: 14px;
  line-height: 32px;
}
.cr-label span {
  font-size: 14px;
  line-height: 32px;
  color: rgba(33, 33, 33, 0.5);
}
.cr-form >>> .el-form-item .el-form-item__content {
  margin: 0;
}
.cr-form >>> .el-form-item {
  margin: 0 0 15px;
}
.cr-form >>> .el-input__inner {
  height: 52px;
}
.cr-form >>> .el-textarea__inner {
  height: 80px;
}
.cr-form >>> .el-button--primary {
  float: right;
  margin: 13px 0 0;
  width: 200px;
  height: 52px;
  background: linear-gradient(to right, #1f5ed1 0%, #447fee 100%);
}
.cr-form >>> .el-button--primary span {
  font-size: 14px;
}
.cr-form >>> input[type="submit"],
.cr-form >>> input[type="text"],
.cr-form >>> input[type="reset"],
.cr-form >>> input[type="button"] {
  font-size: 16px;
  border-radius: 4px;
}
.cr-form >>> input[type="submit"]::placeholder,
.cr-form >>> input[type="text"]::placeholder,
.cr-form >>> input[type="reset"]::placeholder,
.cr-form >>> input::placeholder,
.cr-form >>> .el-textarea__inner::placeholder {
  font-size: 16px;
}

.map {
  margin: 0 20px;
  width: calc(100% - 40px);
}
.map img {
  display: block;
  width: 100%;
}

.footer {
  margin: 0 auto;
}

@media (max-width: 1400px) {
  .container {
    max-width: auto;
  }
}

@media (max-width: 990px) {
  .main {
    margin: 64px auto 0;
  }
  .hidden-xs {
    display: none;
  }
  .hidden-mx {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .container {
    padding: 60px 0 0;
    width: 90%;
  }
  .container-left {
    width: 40%;
  }
  .container-right {
    width: 54%;
  }
  .map {
    margin: 0;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .container {
    padding: 60px 0 0;
    width: 90%;
  }
  .container-left-item {
    margin: 0 0 20px;
  }
}

@media (max-width: 640px) {
  .about {
    background: #f4f6f8;
  }
  .content-title {
    height: 5.6rem;
  }
  .content-title img {
    width: 100%;
    height: 5.6rem;
    object-fit: cover;
  }
  .content-box {
    padding: 0.5rem 0 1.92rem;
  }
  .content-box h1,
  .content-box div {
    padding: 0 0.88rem;
  }
  .content-box h1 {
    font-size: 0.64rem;
    height: 0.84rem;
  }
  .content-box div {
    font-size: 0.28rem;
    line-height: 0.4rem;
  }
  .panel {
    margin: -1.92rem 0 0;
    padding: 0;
  }
  .container {
    display: -webkit-flex;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    margin: 0 0.48rem;
    padding: 0.64rem 0 0.36rem;
    width: calc(100% - 0.96rem);
  }
  .container-left {
    width: 100%;
  }
  .container-left-item {
    margin: 0 0 0.8rem;
    padding: 0 0.48rem;
  }
  .container-left-item:nth-child(1) {
    margin: 0 0 0.3rem;
  }
  .container-left-item h4 {
    font-size: 0.4rem;
    font-weight: 700;
    line-height: 0.64rem;
  }
  .container-left-item p {
    margin: 0.1rem 0 0;
    font-size: 0.32rem;
    line-height: 0.42rem;
  }
  .cl-right {
    padding: 0 0.48rem;
  }
  .container-right {
    padding: 0.44rem 0.48rem 0;
    width: 100%;
    border-top: 0.32rem solid #f4f6f8;
  }
  .cr-title {
    display: none;
  }
  .cr-label {
    font-size: 0.28rem;
    line-height: 0.64rem;
  }
  .cr-label span {
    font-size: 0.28rem;
    line-height: 0.64rem;
  }
  .cr-form >>> .el-form-item {
    margin: 0 0 0.3rem;
  }
  .cr-form >>> .el-input__inner {
    height: 1.04rem;
  }
  .cr-form >>> .el-textarea__inner {
    height: 1.6rem;
  }
  .cr-form >>> .el-button--primary {
    float: right;
    margin: 0.26rem 0 0;
    width: 100%;
    height: 1.04rem;
  }
  .cr-form >>> .el-button--primary span {
    font-size: 0.28rem;
  }
  .cr-form >>> input[type="submit"],
  .cr-form >>> input[type="text"],
  .cr-form input[type="reset"],
  .cr-form >>> input[type="button"] {
    font-size: 0.32rem;
  }
  .cr-form >>> input[type="submit"]::placeholder,
  .cr-form >>> input[type="text"]::placeholder,
  .cr-form >>> input[type="reset"]::placeholder,
  .cr-form >>> input::placeholder,
  .cr-form >>> .el-textarea__inner::placeholder {
    font-size: 0.32rem;
  }

  .map {
    margin: 0.32rem 0 0;
  }
}
</style>
